@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  background-color:#1E293B;
  color: #F1F5F9;
  text-align: center;
}

.clip-background{
  background-image: url(/src/img/clam-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-clip: text;
  background-size:cover;
  -webkit-background-clip: text;
  color: transparent;
  line-height: 10px;

}



